import React from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';
import { About } from '../components/about';
import { Newsletter } from '../components/newsletter';
import { Teams } from '../components/teams';

const AboutUs = () => {
    return (
        <>
            <Navbar />
            <Banner heading={"About Us"} />
            <About />


            {/* TEAM CARD SECTION */}
            <section className='container-fluid teamCardSec'>
                <img src='assets/img/about-team-watermark.png' className='img-fluid waterMark' />
                <div className='container'>
                    <h2 className='sub-heading mb-5'>Our <span>Team</span></h2>

                    <div className='d-flex justify-content-between align-items-center flex-wrap w-100 mb-5'>

                        <Teams />
                        <Teams />
                        <Teams />
                        <Teams />

                    </div>

                    <div className='text-center'>
                        <a href="#" class="btn btn-secondary">See all Team Members</a>
                    </div>
                </div>
            </section>


            <Newsletter />
        </>
    );
}

export default AboutUs;