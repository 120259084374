import React from 'react'

export const Banner = (props) => {
    return (
        <>
            {/* BANNER SECTION */}
            {/* <div className='position-relative featuredImage'>
                <img src='assets/img/listings-banner.png' className='img-fluid w-100' />
                <h1 className='d-flex justify-content-center align-items-center mainHeading'>{props.heading}</h1>
            </div> */}
            <div className='position-relative featuredImage'>
                <img src={props.img ? props.img : 'assets/img/listings-banner.png'} className='img-fluid w-100' />
                <h1 className='d-flex justify-content-center align-items-center mainHeading'>{props.heading}</h1>
            </div>
        </>
    )
}
