import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlickSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        fadeSpeed: 1000,
        autoplay: true,
        fade: true,
        infinite: true,
        arrows: false,
        autoplaySpeed: 3000,
        initialSlide: 1,
    };
  
    return (
      <div>
        <Slider {...settings}>
          <div>
          <img src="assets/img/hero-02.jpg" className="img-fluid w-100" />
          </div>
          <div>
          <img src="assets/img/hero-01.png" className="img-fluid w-100" />
          </div>
          <div>
          <img src="assets/img/hero-02.jpg" className="img-fluid w-100" />
          </div>
          {/* Add more slides as needed */}
        </Slider>
      </div>
    );
  };
  
  export default SlickSlider;
  