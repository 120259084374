import React from 'react';

export const Teams = () => {

    return (
        <>
            <div className="card">
                <div className='position-relative'>
                    <img className="card-img-top img-fluid w-100" src='assets/img/about-team-avatar-01.png' alt="Card image cap" />
                </div>
                <div className="card-body">
                    <div className='d-flex justify-content-between align-items-center'><h3>KYLE GULOCK</h3><span>LIC # 01861385 (CA)</span></div>
                    <h4 className='mb-3'>EXECUTIVE VICE PRESIDENT</h4>
                    <p><img src='assets/img/about-t-icon-01.png' className='img-fluid mb-2' /> Work: (310) 691-1350 ext. 125</p>
                    <p className='mb'><img src='assets/img/about-t-icon-02.png' className='img-fluid mb-2' />Mobile: (818) 493-0493</p>
                    <p><img src='assets/img/about-t-icon-03.png' className='img-fluid mb-2' /> Email: 133kgulock@pegasusinvestments.com</p>
                    <hr></hr>
                    <a href='#'>More</a>
                </div>
            </div>
        </>
    )
}