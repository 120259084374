import React from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';

const CaseStudy = () => {
    return (
    <div>
        <Navbar />
        <Banner heading={"Case Studies"} />
        

        <section className='container caseStudyTabs' >
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="All" aria-selected="true">All</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="1031-Exchange-tab" data-bs-toggle="tab" data-bs-target="#1031-Exchange" type="button" role="tab" aria-controls="1031-Exchange" aria-selected="false">1031 Exchange</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="acquisition-tab" data-bs-toggle="tab" data-bs-target="#acquisition" type="button" role="tab" aria-controls="Acquisition" aria-selected="false">Acquisition</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="disposition-tab" data-bs-toggle="tab" data-bs-target="#disposition" type="button" role="tab" aria-controls="Disposition" aria-selected="false">Disposition</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="lease-tab" data-bs-toggle="tab" data-bs-target="#lease" type="button" role="tab" aria-controls="Lease" aria-selected="false">Lease</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="sale-tab" data-bs-toggle="tab" data-bs-target="#sale" type="button" role="tab" aria-controls="Sale" aria-selected="false">Sale</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="leaseback-tab" data-bs-toggle="tab" data-bs-target="#leaseback" type="button" role="tab" aria-controls="Leaseback" aria-selected="false">Leaseback</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                {/* All */}
                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                    <div className="card mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/home-property-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Acquisition</h6>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row flex-dir-rev">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/case-study-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Lease</h6>
                                </div>    
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/home-property-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Sale</h6>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row flex-dir-rev">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/case-study-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Acquisition</h6>
                                </div>    
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/home-property-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Leaseback</h6>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row flex-dir-rev">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/case-study-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>1031 Exchange</h6>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>

                {/* 1031 EXCHANGE */}
                <div className="tab-pane fade" id="1031-Exchange" role="tabpanel" aria-labelledby="1031-Exchange-tab">
                    <div className="card mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/home-property-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>1031 Exchange</h6>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row flex-dir-rev">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/case-study-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>1031 Exchange</h6>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* Acquisition */}
                <div className="tab-pane fade" id="acquisition" role="tabpanel" aria-labelledby="acquisition-tab">
                    <div className="card mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/home-property-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Acquisition</h6>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row flex-dir-rev">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/case-study-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Acquisition</h6>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>

                {/* Disposition */}
                <div className="tab-pane fade" id="disposition" role="tabpanel" aria-labelledby="disposition-tab">
                    <div className="card mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/home-property-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Disposition</h6>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row flex-dir-rev">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/case-study-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Disposition</h6>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>

                {/* LEASE */}
                <div className="tab-pane fade" id="lease" role="tabpanel" aria-labelledby="lease-tab">
                    <div className="card mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/home-property-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Lease</h6>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row flex-dir-rev">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/case-study-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Lease</h6>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>

                {/* SALE */}
                <div className="tab-pane fade" id="sale" role="tabpanel" aria-labelledby="sale-tab">
                <div className="card mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/home-property-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>sale</h6>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row flex-dir-rev">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/case-study-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Sale</h6>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>

                {/* LEASEBACK */}
                <div className="tab-pane fade" id="leaseback" role="tabpanel" aria-labelledby="leaseback-tab">
                <div className="card mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/home-property-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Leaseback</h6>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row flex-dir-rev">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/case-study-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Leaseback</h6>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center"><a href="#" class="btn btn-secondary">View All Case Studies</a></div>

        </section>

    </div>
    );
}

export default CaseStudy;