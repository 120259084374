import React from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';

const Exchange = () => {
    return (
    <div>
        <Navbar />
        <Banner heading={"1031 Exchanges"} />

        <section className='container exchangeTopSec'>
            <div className='row gx-5 justify-content-between align-items-center pb-5'>
                <div className='col-lg-6'>
                    <h2 className='sub-heading mb-2'>About <span>1031 Exchanges</span></h2>
                    <p>A 1031 exchange allows property owners to avoid paying capital gains taxes when selling investment property. The proceeds from the sale must be reinvested within certain time limits in a property, or properties, of like kind and equal or greater value.</p>
                    <br></br>
                    <p>The 1031 exchange process differs significantly from a typical purchase. In addition to the typical escrow process, it requires the seller to identify a replacement property (called the “upleg”) within a 45-day period from closing the original investment (called the “downleg”) and engage an exchange accommodator to work with escrow during the downleg’s closing process. The exchange purchase must be completed within 180 days. Given the time constraints, it is crucial to control the process effectively, and the Pegasus team is highly adept and experienced with executing the transaction smoothly and professionally.</p>
                </div>
                <div className='col-lg-6 text-lg-end'>
                    <img src='assets/img/exchange-01.png' className='img-fluid'/>
                </div>
            </div>
            <div className='row mt-5 gx-5 justify-content-between align-items-center r-reverse'>
            <div className='col-lg-6 text-lg-start'>
                    <img src='assets/img/exchange-02.png' className='img-fluid'/>
                </div>
                <div className='col-lg-6'>
                    <h2 className='sub-heading mb-2'>About <span>Triple Net Leases</span></h2>
                    <p>Net leases are highly sought after by 1031 exchange investors. An absolute triple net lease is a lease agreement in place between Landlord and Tenant where tenant pays Landlord a fixed amount of money, usually monthly, and the tenant is responsible for paying all expenses associated with the property. The landlord benefits from a predictable income stream with minimal, if any, asset management required on their part. During the closing process, Pegasus Asset Management can advise the new owner on the management of the property and/or take over management needs.</p>
                    
                </div>
            </div>
        </section>


        <section className='container-fluid benefitSec'>
        <h2 className='sub-heading text-center'>Benefits of <span>1031 exchange</span> into net leased assets:</h2>
            <div className='d-flex justify-content-center flex-wrap'>
                <div className='text-center'>
                    <img src='assets/img/exchange-benefit-01.png' className='img-fluid'/>
                    <h6>Simple, hands-off management structure</h6>
                </div>

                <div className='text-center mt-40'>
                    <img src='assets/img/exchange-benefit-04.png' className='img-fluid'/>
                    <h6>Long term leases</h6>
                </div>

                <div className='text-center'>
                    <img src='assets/img/exchange-benefit-03.png' className='img-fluid'/>
                    <h6>Investment portfolio diversification</h6>
                </div>

                <div className='text-center mt-40'>
                    <img src='assets/img/exchange-benefit-02.png' className='img-fluid'/>
                    <h6>Tax deferment</h6>
                </div>
            </div>

            
        </section>  


        <section className='timeLine container-fluid'>
            <h2 className='sub-heading text-center'>Process <span>Timeline</span></h2>
            <div className='roadMap'> 
                <div class="container-t right">
                    <div class="content">
                    <h2>Initial Consultation</h2>
                    </div>
                </div>   
                <div class="container-t left map-xz">
                    <div class="content">
                    <h2>Capital Markets Engagement</h2>
                    <p>(If Financing Is Required)</p>
                    </div>
                </div>
                <div class="container-t right">
                    <div class="content">
                    <h2>Legal Counsel Selected</h2>
                    </div>
                </div>
                <div class="container-t left map-xz">
                    <div class="content">
                    <h2>Play book stage</h2>
                    <p>(Deal Criteria Outlined & Plan of Attack Launched)</p>
                    </div>
                </div>
                <div class="container-t right">
                    <div class="content">
                    <h2>Available Opportunities Assessment</h2>  
                    </div>
                </div>
                <div class="container-t left">
                    <div class="content">
                    <h2>Investment Research Phase</h2>
                    </div>
                </div>
                <div class="container-t right">
                    <div class="content">
                    <h2>Transaction Terms Negotiated</h2>
                    </div>
                </div>
                <div class="container-t left">
                    <div class="content">
                    <h2>Property Is Under Contract</h2>
                    </div>
                </div>
                <div class="container-t right map-x">
                    <div class="content">
                    <h2 className='mb-2'>Due Diligence Conducted</h2>
                    <p className='txtsmall'>(Pegasus works in conjunction with your counsel to complete buyside<br></br> investment criteria review checklist)</p>
                    </div>
                </div>
                <div class="container-t left">
                    <div class="content mb-0">
                    <h2>Successful Transaction - <br></br>1031 Exchange Completed</h2>
                    </div>
                </div>
            </div>    
        </section>

    </div>
    );
}

export default Exchange;