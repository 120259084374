import React from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';

const CaseStudyDetail = () => {
    return (
        <div>
            <Navbar />
            <Banner heading={"Tesla Sale Case study"} img={'assets/img/casestudy-detail-banner.png'} />

            <section className='container caseStudyDetails'>

                <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                <h6>ORLAND PARK , IL (CHICAGO)</h6>
                <hr className='mb-4'></hr>
                <ul>
                    <li>Procured an off-market deal to 1031 exchange client</li>
                    <li>Assessed and quantified risk</li>
                    <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                    <li>Renegotiated terms with seller to protect client</li>
                    <li>Successfully reached the finish line</li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus imperdiet cursus rhoncus. Cras euismod iaculis eros eget iaculis. Donec id nunc ut lorem congue tincidunt quis vel ipsum. Vivamus quis gravida leo. Quisque ullamcorper, sem id ullamcorper euismod, quam nisi tempor libero, sed lacinia nulla diam at nibh. Integer eu metus ullamcorper, lacinia nunc vel, fringilla orci. Donec vitae turpis laoreet, scelerisque metus eget, tempor arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur ornare maximus magna non facilisis. Cras consequat est sed blandit pretium. Vestibulum fringilla nibh quis scelerisque porttitor.<br></br><br></br> Etiam eu condimentum mauris. Praesent scelerisque mi non efficitur auctor. Morbi interdum sapien non bibendum pharetra. Sed vitae eros eu quam tristique malesuada vitae vel felis. Pellentesque a hendrerit urna. Curabitur vitae lacus sem. Cras tincidunt laoreet neque at feugiat. Nulla et massa quis turpis hendrerit posuere in et nibh. Nunc eros nisl, vehicula et arcu nec, euismod mollis est. Integer lacinia, elit volutpat mollis ullamcorper, lorem est volutpat nisi, vel ornare nisl arcu ut nisl. Donec justo velit, congue non ligula in, malesuada maximus turpis. In varius vestibulum nisi ac lacinia. In consectetur velit nibh, vel dapibus lorem p</p>
                <hr className='mt-5 pb-5'></hr>

                <div className='moreCase mt-5'>
                    <h2 class="sub-heading mb-5 text-center">More <span>Case Studies</span></h2>
                    <div className="card mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/home-property-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Acquisition</h6>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-5">
                        <div className="row flex-dir-rev">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h3>TESLA SALES, SERVICE & DELIVERY</h3>
                                    <h4>ORLAND PARK , IL (CHICAGO)</h4>
                                    <ul>
                                        <li>Procured an off-market deal to 1031 exchange client</li>
                                        <li>Assessed and quantified risk</li>
                                        <li>Navigated an eminent domain issue that occurred 2 days before closing</li>
                                        <li>Renegotiated terms with seller to protect client</li>
                                        <li>Successfully reached the finish line</li>
                                    </ul>
                                    <a href="#" className="btn btn-secondary">View case study</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='position-relative h-100 w-100'>
                                    <img src="assets/img/case-study-01.png" className="img-fluid h-100 w-100"/>
                                    <h6>Lease</h6>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default CaseStudyDetail;