import React from 'react';
import { NavLink } from "react-router-dom";

const Navbar = () => {
  
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-xl header mainNav" id="mainNav">
          <div className="container-fluid">
            <NavLink to="/" className="logoBrand"><img src="assets/img/logo.svg" alt="*" className="img-fluid" /></NavLink>
            <div className="offcanvas offcanvas-start-lg" tabindex="-1" id="offcanvasExample"
              aria-labelledby="offCanvasMenu">
              <div className="offcanvas-header d-flex d-xl-none">
                <h5 className="offcanvas-title" id="offCanvasMenu"><NavLink to="/" className="logoBrand"><img src="assets/img/logo.svg" alt="*" className="img-fluid" /></NavLink></h5>
                <a href="javascript:void(0)" className="text-reset p-0" data-bs-dismiss="offcanvas"
                  aria-label="close">
                  <img src="assets/img/icon-close.png" width="24" height="24" alt="close" />
                </a>
              </div>
              <div className="offcanvas-body justify-content-between">
                <ul className="navbar-nav mr-auto mb-2 mb-lg-0 mainMenu align-items-xl-center">
                  <li className="nav-item dropdown">
                    <NavLink className="nav-link" id="navbarDropdown" to="/about-us">About</NavLink>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><NavLink className="dropdown-item" to="/team">Team</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/contact">Contact</NavLink></li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/listings">Listings</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/exchange">1031 Exchanges</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/tenant-advisory">Tenant Advisory</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/case-study">Case Studies</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/contact">Contact Us</NavLink>
                  </li>
                </ul>
                <div className="d-flex align-items-center">
                  <NavLink to="/login" className="btn">Sign In</NavLink>
                  <NavLink to="/register" className="btn btn-primary">Register</NavLink>
                </div>
              </div>
            </div>
            <button className="btn navbar-toggler border-3 px-2" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
              <img src="assets/img/icon-menu.png" width="30" height="30" alt="menu icon" />
            </button>
          </div>
        </nav>
      </header>

    </>
  )
}

export default Navbar;