import React from 'react';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="d-flex flex-wrap justify-content-between align-items-start">
                        <div>
                            <h3>CENTURY PARTNERS</h3>
                            <p className='text-lightGrey text-uppercase'>PO BOX 1161 (From 1161 To 1176), LOS ANGELES, CA</p>
                            <div className="footIcon">
                                <a href="#"><img src="assets/img/phone.png" className="img-fluid" /></a>
                                <a href="#"><img src="assets/img/emailalt.png" className="img-fluid" /></a>
                                <a href="#"><img src="assets/img/linkedin.png" className="img-fluid" /></a>
                            </div>
                        </div>

                        <div>
                            <ul>
                                <li><a href="tel:123.456.7890">123.456.7890</a></li>
                                <li><a href="mailto:info@centurypartners.com">info@centurypartners.com</a></li>
                            </ul>
                        </div>

                        <div>
                            <ul>
                                <li><a href="#">About</a></li>
                                <li><a href="#">Listings</a></li>
                                <li><a href="#">1031 Exchange</a></li>
                                <li><a href="#">Case Studies</a></li>
                                <li><a href="#">Contact Us</a></li>
                            </ul>
                        </div>

                        <div>
                            <ul>
                                <li><a href="#">Terms of Use</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Contact</a></li>
                            </ul>
                        </div>

                        <div>
                            <img src="assets/img/footer-logo.png" className="img-fluid" />
                        </div>
                    </div>

                    <div className="footerContent">
                        <p className="text-lightGrey mb-2">©2023 Century Partners. All rights reserved.</p>
                        <p className="smallTxt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi luctus sit amet dui
                        ac elementum. Aenean gravida magna enim, vel bibendum elit facilisis volutpat. Sed facilisis dui eu
                        nisl tincidunt maximus. Cras ultricies at tellus vel tincidunt. Maecenas id feugiat risus, at
                        molestie odio. Fusce sollicitudin porta velit sit amet iaculis. Sed suscipit lacus dictum lorem
                    facilisis eleifend.<br /> Phasellus interdum neque vitae porta sollicitudin. Cras a erat ullamcorper,
                            fringilla erat ac, ultricies ante. Vivamus viverra sapien euismod tortor consectetur, nec efficitur
                            elit scelerisque. Aliquam ac enim dapibus, accumsan est vel, malesuada erat. Mauris vitae facilisis
                    augue. Mauris mollis gravida eleifend.</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;