import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { ListingCard } from '../components/listingCard';
import Arr from '../components/data'

const SplideSlider = () => {
    const settings = {
      type: 'loop',
      perPage: 2.5,
      perMove: 1,
      autoplay: false,
      interval: 2000,
      pagination: true,
      drag: true,
      gap: '38px',
      breakpoints: {
      520: {
        perPage: 1,
      },
      767: {
        perPage: 1.5
      },
      }
    };
    return (
      <Splide options={settings}>
        {
            Arr.map((item) => {
                return (
                  <SplideSlide>
                        <>
                            <ListingCard data={item} key={item.id} />
                        </>
                  </SplideSlide>
                )
            })
        }
      </Splide>
    );
  };
  
  export default SplideSlider;
