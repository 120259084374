import React from 'react';
import Navbar from '../components/navbar';
import SlickSlider from '../components/SlickSlider';
import { ListingCard } from '../components/listingCard';
import { About } from '../components/about';
import SplideSlider from '../components/SplideSlider';
import { Newsletter } from '../components/newsletter';
import Arr from '../components/data'

const Home = () => {
    return (
        <div>
            <Navbar />
            <>

                {/* HERO SECTION */}
                {/* <div className="slider HeaderSlider">
                    <div className="slide">
                        <img src="assets/img/hero-02.png" className="img-fluid w-100" />
                    </div>
                    <div className="slide">
                        <img src="assets/img/hero-01.png" className="img-fluid w-100" />
                    </div>
                    <div className="slide">
                        <img src="assets/img/hero-02.png" className="img-fluid w-100" />
                    </div>
                </div> */}
                <div className='HeaderSlider'>
                <SlickSlider />
                </div>

                <About />

                {/* CARD CAROUSEL SECTION */}
                <section className='container-fluid cardCarouselSec'>
                    <img src='assets/img/home-carousel-watermark.png' className='img-fluid waterMark' />
                    <div className='container mb-5'>
                        <h2 className='sub-heading'>Current <span>Listings</span></h2>
                    </div>

                    <div id="cardCarousel">
                        <SplideSlider />
                    </div>

                    {/* <div id="cardCarousel" className="splide">
                        <div className="splide__track">
                            <ul className="splide__list">
                                
                                {
                                    Arr.map((item) => {
                                        return (
                                            <li className="splide__slide">
                                                <>
                                                    <ListingCard data={item} key={item.id} />
                                                </>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div> */}

                    <div className='text-center'>
                        <a href="#" class="btn btn-secondary" type="submit">View All Properties</a>
                    </div>
                </section>

                {/* PROJECT SECTION */}
                <section className="container-fluid projectSec">
                    <div className="d-flex flex-wrap">
                        <div>
                            <a href="#" className="position-relative">
                                <img src="assets/img/home-project-1.png" className="img-fluid w-100" />
                                <div>
                                    <h2>1031 Exchanges</h2>
                                    <button className="btn">Learn More</button>
                                </div>
                            </a>
                        </div>

                        <div>
                            <a href="#" className="position-relative">
                                <img src="assets/img/home-project-2.png" className="img-fluid w-100" />
                                <div>
                                    <h2>Case Studies</h2>
                                    <button className="btn">Learn More</button>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>

                {/* NEWSLETTER SECTION */}
                <Newsletter />
            </>

        </div>
    );
}

export default Home;