import React from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';
import { ListingCard } from '../components/listingCard';
import Arr from '../components/data'

const Listings = () => {

   

    return (
        <div>
            <Navbar />
            <Banner heading={"Listings"} />
            <>
                {/* LISTINGS SECTION */}
                <section className='listings'>
                    <div className='container'>
                        <div className='d-flex justify-content-between align-items-center flex-wrap w-100'>

                            {
                                Arr.map((item) => {
                                    return (
                                        <>
                                            <ListingCard data={item} key={item.id} />
                                        </>
                                    )
                                })
                            }
                        </div>

                        <div className='text-center mt-5'>
                            <a href="#" class="btn btn-secondary mt-4" type="submit">View All Lisitng</a>
                        </div>
                    </div>
                </section>
            </>
        </div>
    );
}

export default Listings;