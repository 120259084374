import React from 'react';
import Navbar from '../components/navbar';
import { Teams } from '../components/teams';
// import { Banner } from '../components/banner';

const TeamDetail = () => {
    return (
        <>
            <div className='secondary-header'>
            <Navbar />
            </div>
            {/* <Banner heading={"Team dsas"} /> */}


            {/* TEAM DETAILS SECTION */}
            <section className='container teamDetails'>
                <div className='row'>
                    <div className='col-md-6'>
                        <img src='assets/img/team-details-single.png' className='img-fluid'/>
                        <div className='d-flex align-items-center justify-content-center flex-wrap pt-4'>
                            <a href='#' className='btn btn-download'>Download VCard</a>
                            <a href='#' className='btn btn-primary'>Contact Us</a>
                        </div>
                        <div className='d-flex align-items-center justify-content-center pt-4 teamDetailsSocial'>
                            <a href='#'><img src='assets/img/team-details-fb.png' className='img-fluid'/></a>
                            <a href='#'><img src='assets/img/team-details-linkedin.png' className='img-fluid'/></a>
                            <a href='#'><img src='assets/img/team-details-youtube.png' className='img-fluid'/></a>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="card">
                            <div className="card-body">
                                <div className='d-flex justify-content-between align-items-center'><h3>MATT KRAMER</h3><span>LIC # 01995909</span></div>
                                <h4 className='mb-3'>VICE PRESIDENT</h4>
                                <p><img src='assets/img/about-t-icon-01.png' className='img-fluid mb-2' /><strong>Work:</strong> (310) 691-1350 ext. 125</p>
                                <p className='mb'><img src='assets/img/about-t-icon-02.png' className='img-fluid mb-2' /><strong>Mobile:</strong> (818) 493-0493</p>
                                <p className='mb-3'><img src='assets/img/about-t-icon-03.png' className='img-fluid mb-2' /><strong>Email:</strong> 133kgulock@pegasusinvestments.com</p>
                                <p className='full-description'>Matt Kramer is a Vice President at Pegasus Investments, where he specializes in advisory services for quick service restaurant-occupied properties. His team, consisting of Kyle Gulock and Dylan Cohen, focuses on the disposition and acquisition of net leased retail properties.<br></br><br></br>Mr. Kramer has built his reputation on exclusively representing buyers and sellers for real estate opportunities that produce long-term stable cash flow. He shares his clients’ desire for shrewd value-add solutions and has the flexibility to mine opportunities for a wide range of capital placement requirements. Mr. Kramer’s clients trust his expertise in the quick-service restaurant space through his intimate knowledge of the unique strategies required for this asset type.<br></br><br></br>Prior to joining Pegasus, Mr. Kramer provided net lease advisory services at Kidder Mathews and Charles Dunn Company in Los Angeles. Mr. Kramer began his career handling administration and business development at a Los Angeles-based law firm that specialized in franchise law and litigation, where he deepened his understanding of franchise operations.<br></br><br></br>Mr. Kramer graduated from the University of Oregon and currently resides in West Los Angeles.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            
            {/* TEAM CARD SECTION */}
            <section className='container-fluid teamCardSec'>
                <img src='assets/img/about-team-watermark.png' className='img-fluid waterMark' />
                <div className='container'>
                    <h2 className='sub-heading mb-5'>Our <span>Team</span></h2>

                    <div className='d-flex justify-content-between align-items-center flex-wrap w-100 mb-5'>

                        <Teams />
                        <Teams />
                        <Teams />
                        <Teams />

                    </div>

                    <div className='text-center'>
                        <a href="#" class="btn btn-secondary">See all Team Members</a>
                    </div>
                </div>
            </section>

        </>
    );
}

export default TeamDetail;