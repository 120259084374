import React from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';

const TenantAdvisory = () => {
    return (
    <div>
        <Navbar />
        <Banner heading={"Tenant Advisory"} />
        
        <section className='container portfolioServices'>
            <h2 className='sub-heading mb-2'>Portfolio <span>Services</span></h2>
            <p>Our full-service platform offers multiple services for occupiers to expand, recapitalize or optimize their portfolios.</p>
        
            <div className='row'>
                <div className='col-lg-4 col-md-6'>
                    <div className='portservBox text-center'>
                        <img src='assets/img/tenant-protserve-01.png' className='img-fluid'/>
                        <h3>Build-to-Suit</h3>
                        <p>Partner with Pegasus to select a developer or capital group that can finance and/or build out your expansion plans.</p>
                    </div>
                </div>

                <div className='col-lg-4 col-md-6'>
                    <div className='portservBox text-center'>
                        <img src='assets/img/tenant-protserve-02.png' className='img-fluid'/>
                        <h3>Sale-Leaseback</h3>
                        <p>We are industry leaders in achiving record pricing with seamless execution.</p>
                    </div>
                </div>

                <div className='col-lg-4 col-md-6'>
                    <div className='portservBox text-center'>
                        <img src='assets/img/tenant-protserve-03.png' className='img-fluid'/>
                        <h3>Real Estate Execution</h3>
                        <p>We can assist with all aspects of real estate strategy with regards to your operations or portoflio.</p>
                    </div>
                </div>
            </div>
        </section> 


        <section className='container-fluid tenantAdvisory'>
            <div className='d-flex justify-content-between flex-wrap'>
                <div className='col-t'>
                    <img src='assets/img/tenant-01.png' className='img-fluid'/>
                </div>
                <div className='col-t'>
                    <img src='assets/img/tenant-watermark.png' className='img-fluid advisoryWmark'/>    
                    <img src='assets/img/tenant-02.png' className='img-fluid'/>
                </div>
            </div>
        </section> 


        <section className='container-fluid suitSec'>
        <h2 className='sub-heading text-center'>THE BUILD TO SUIT <br></br><span>PROCESS</span></h2>
            <div className='d-flex justify-content-center flex-wrap'>
                <div className='text-center'>
                    <img src='assets/img/tenant-suit-01.png' className='img-fluid'/>
                    <h6>Initial consultation & strategy Development</h6>
                </div>

                <div className='text-center mt-40'>
                    <img src='assets/img/tenant-suit-02.png' className='img-fluid'/>
                    <h6>Identify developers that match the selected strategy</h6>
                </div>

                <div className='text-center'>
                    <img src='assets/img/tenant-suit-03.png' className='img-fluid'/>
                    <h6>Obtain & Review term sheets from multiple developers</h6>
                </div>

                <div className='text-center mt-40'>
                    <img src='assets/img/tenant-suit-04.png' className='img-fluid'/>
                    <h6>Manage development and closing execution</h6>
                </div>

                <div className='text-center'>
                    <img src='assets/img/tenant-suit-05.png' className='img-fluid'/>
                    <h6>Orderly transition from developer to tenant</h6>
                </div>
            </div>
        
                <h2 className='processWmark'>Process</h2>
            
        </section>  

        <section className='container-fluid leaseBackSec'>
            <h2 className='sub-heading text-center'>THE SALE-LEASEBACK <br></br><span>PROCESS</span></h2>
            <div className='d-flex justify-content-center flex-wrap r1'>
                <div className='text-center'>
                    <img src='assets/img/tenant-lead-03.png' className='img-fluid'/>
                    <h6>Portfolio evaluation and strategic analysis</h6>
                </div>

                <div className='text-center mt-40'>
                    <img src='assets/img/tenant-lead-03.png' className='img-fluid'/>
                    <h6>optimize lease for operational efficiency and marketability</h6>
                </div>

                <div className='text-center'>
                    <img src='assets/img/tenant-lead-07.png' className='img-fluid'/>
                    <h6>Create marketing materials and launch property to market</h6>
                </div>

                <div className='text-center mt-40'>
                    <img src='assets/img/tenant-lead-06.png' className='img-fluid'/>
                    <h6>Collect offers & vet Potential buyers</h6>
                </div>
            </div>    
            <div className='d-flex justify-content-center flex-wrap r2'>
                <div className='text-center'>
                    <img src='assets/img/tenant-lead-04.png' className='img-fluid'/>
                    <h6>Select buyer and negotiate sale agreement</h6>
                </div>
                <div className='text-center'>
                    <img src='assets/img/tenant-lead-05.png' className='img-fluid'/>
                    <h6>Open Escrow, coordinate due diligence delivery</h6>
                </div>
                <div className='text-center'>
                    <img src='assets/img/tenant-lead-01.png' className='img-fluid'/>
                    <h6>Track escrow dates and Resolve conflicts</h6>
                </div>
                <div className='text-center'>
                    <img src='assets/img/tenant-lead-02.png' className='img-fluid'/>
                    <h6>Complete Closing execution and coordinate property’s delivery</h6>
                </div>
            </div>
        </section>
    </div>
    );
}

export default TenantAdvisory;