import React from 'react'

export const Newsletter = () => {
    return (
        <>
            <section className="container newsLetterSec">
                <h2 className='sub-heading'>Connect <span>With us</span></h2>
                <p>Get the Latest Market Updates and Exclusive Inventory</p>
                <form>
                    <div className="input-group justify-content-between align-items-end">
                        <input type="email" className="form-control" name="newsletterEmail" id="newsletterEmail"
                            placeholder="Your email" />
                        <span className="input-group-btn">
                            <button className="btn btn-secondary" type="submit">Submit</button>
                        </span>
                    </div>
                </form>
            </section>
        </>
    )
}
