import React from 'react';
import Navbar from '../components/navbar';

const singleListing = () => {
    return (
    <div>
        <Navbar />
        <div>
            <p>singleListing</p>
        </div>

    </div>
    );
}

export default singleListing;