import React from 'react';
import Navbar from '../components/navbar';
import { Banner } from '../components/banner';

const Contact = () => {
    return (
    <div>
        <Navbar />
        <Banner heading={"Contact Us"} />

        <section className="container contactSec">
            <div className="row mb-5">
                <div className="col-md-6 pt-md-5">
                    <h2 className="sub-heading">Contact <span>info</span></h2>
                    <ul className="mt-4">
                        <li><img src="assets/img/contact-map.png" className="img-fluid"/><p>Pegasus Investments Real Estate Advisory Inc. 1901 Avenue of the Stars, Suite 630 Los Angeles, CA 90067</p></li>
                        <li><img src="assets/img/contact-phone.png" className="img-fluid"/><p>(310) 691-1350</p></li>
                        <li><img src="assets/img/contact-lic.png" className="img-fluid"/><p>LIC # 01961545 (CA)</p></li>
                    </ul>
                    <hr></hr>
                    <h2 className="submini-heading">Get <span>Connect</span></h2>
                    <div className="d-flex align-items-center contactSocialMedia">
                        <img src="assets/img/contact-fb.png" className="img-fluid"/>
                        <img src="assets/img/contact-linkedin.png" className="img-fluid"/>
                        <img src="assets/img/contact-youtube.png" className="img-fluid"/>
                        <img src="assets/img/contact-insta.png" className="img-fluid"/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="teamBox">
                        <h2 className="sub-heading">Advisory <span>team</span></h2>
                        
                        <div className="d-lg-flex justify-content-between mt-4">
                            <div className="position-relative">
                                <img className="card-img-top img-fluid" src="assets/img/about-team-avatar-01.png" alt="Card image cap"/>
                            </div>
                            <div className="card-body">
                                <h3>CONNOR ROBIN</h3>
                                <h4 className="mb-2">ANALYST</h4>
                                <p><img src="assets/img/about-t-icon-01.png" className="img-fluid mb-2"/><strong>Work:</strong> (310) 691-1350 ext. 105</p>
                                <p className="mb"><img src="assets/img/about-t-icon-02.png" className="img-fluid mb-2"/><strong>LIC:</strong> #02202589 (CA)</p>
                                <p><img src="assets/img/about-t-icon-03.png" className="img-fluid mb-2"/><strong>Email:</strong> crobin@pegasusinvestments.com</p>
                            </div>
                        </div>

                        <div className="d-lg-flex justify-content-between mt-4">
                            <div className="position-relative">
                                <img className="card-img-top img-fluid" src="assets/img/about-team-avatar-01.png" alt="Card image cap"/>
                            </div>
                            <div className="card-body">
                                <h3>CONNOR ROBIN</h3>
                                <h4 className="mb-2">ANALYST</h4>
                                <p><img src="assets/img/about-t-icon-01.png" className="img-fluid mb-2"/><strong>Work:</strong> (310) 691-1350 ext. 105</p>
                                <p className="mb"><img src="assets/img/about-t-icon-02.png" className="img-fluid mb-2"/><strong>LIC:</strong> #02202589 (CA)</p>
                                <p><img src="assets/img/about-t-icon-03.png" className="img-fluid mb-2"/><strong>Email:</strong> crobin@pegasusinvestments.com</p>
                            </div>
                        </div>

                        <div className="d-lg-flex justify-content-between mt-4">
                            <div className="position-relative">
                                <img className="card-img-top img-fluid" src="assets/img/about-team-avatar-01.png" alt="Card image cap"/>
                            </div>
                            <div className="card-body">
                                <h3>CONNOR ROBIN</h3>
                                <h4 className="mb-2">ANALYST</h4>
                                <p><img src="assets/img/about-t-icon-01.png" className="img-fluid mb-2"/><strong>Work:</strong> (310) 691-1350 ext. 105</p>
                                <p className="mb"><img src="assets/img/about-t-icon-02.png" className="img-fluid mb-2"/><strong>LIC:</strong> #02202589 (CA)</p>
                                <p><img src="assets/img/about-t-icon-03.png" className="img-fluid mb-2"/><strong>Email:</strong> crobin@pegasusinvestments.com</p>
                            </div>
                        </div>

                        <div className="d-lg-flex justify-content-between mt-4">
                            <div className="position-relative">
                                <img className="card-img-top img-fluid" src="assets/img/about-team-avatar-01.png" alt="Card image cap"/>
                            </div>
                            <div className="card-body">
                                <h3>CONNOR ROBIN</h3>
                                <h4 className="mb-2">ANALYST</h4>
                                <p><img src="assets/img/about-t-icon-01.png" className="img-fluid mb-2"/><strong>Work:</strong> (310) 691-1350 ext. 105</p>
                                <p className="mb"><img src="assets/img/about-t-icon-02.png" className="img-fluid mb-2"/><strong>LIC:</strong> #02202589 (CA)</p>
                                <p><img src="assets/img/about-t-icon-03.png" className="img-fluid mb-2"/><strong>Email:</strong> crobin@pegasusinvestments.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="row pt-5">
                <div className="col-md-12">
                    <h2 className="sub-heading">Leave a <span>Message</span></h2>
                    <form>
                        <div className="form row">
                            <div className="input-grp col-md-6">
                                <input type="text" className="form-control" name="name" id="name"/>
                                <label for="name" className="">Name</label>
                            </div>

                            <div className="input-grp col-md-6">
                                <input type="text" className="form-control" name="lastName" id="lastName"/>
                                <label for="lastName" className="">Last Name</label>
                            </div>
                            
                            <div className="input-grp col-md-6">
                                <input type="email" className="form-control" name="email" id="email"/>
                                <label for="email" className="">Email</label>
                            </div>

                            <div className="input-grp col-md-6">
                                <input type="text" className="form-control" name="subject" id="subject"/>
                                <label for="subject" className="">Subject</label>
                            </div>

                            <div className="input-grp col-md-12">
                                <textarea name="details" className="form-control" id="details" cols="30" rows="10"></textarea>
                                <label for="details" className="">Details / Notes</label>
                            </div>

                            <div className="input-group">
                                <button type="submit" className="btn btn-primary" value="Login Now">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>


        <section className="container-fluid contactMap">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.385800879323!2d-118.42033612340238!3d34.059623117338916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bb8b630bd9b5%3A0x30a5194a0b831436!2sPegasus%20Investments%20Real%20Estate%20Advisory%20Inc.!5e0!3m2!1sen!2s!4v1694533425884!5m2!1sen!2s" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>

        

    </div>
    );
}

export default Contact;