import React from 'react'

export const ListingCard=({data})=> {
    return (
        <>
                <div className="card">
                    <div className='position-relative'>
                        <img className="card-img-top img-fluid w-100" src={data.img} alt="Card image cap" />
                        <h6>Price: <span>${data.price}</span></h6>
                    </div>
                    <div className="card-body">
                        <a href="#"><h3>{data.name} {data.id}</h3></a>
                        <h4>{data.address}</h4>
                        <hr></hr>
                        <div className='d-flex justify-content-between'>
                            <p>CAP Rate:<span>Call for Details</span></p>
                            <p>Size:<span>{data.size} SF</span></p>
                            <p>Tenancy:<span>{data.tenancy}</span></p>
                        </div>
                    </div>
                </div>
            
        </>
    )
}
