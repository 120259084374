import React from 'react';
import Navbar from '../components/navbar';

const Register = () => {
    return (
    <div>
        <div className='secondary-header'>
        <Navbar />
        </div>

        <section className="container-fluid signUpSec">
        <div className="row">
            <div className="col-md-6">
                <h2 className="sub-heading">Create your <span>account</span></h2>
                <hr></hr>
                <form>
                    <div className="form">
                        <div className="input-group">
                            <input type="text" className="form-control" name="name" id="name"/>
                            <label for="name" className="">Name *</label>
                        </div>

                        <div className="input-group">
                            <input type="email" className="form-control" name="email" id="email"/>
                            <label for="email" className="">Email *</label>
                        </div>

                        <div className="input-group">
                            <input type="password" className="form-control" name="password" id="password"/>
                            <label for="password" className="">Password *</label>
                        </div>

                        <div className="input-group">
                            <button type="submit" className="btn btn-primary w-100" value="Login Now">Login Now</button>
                        </div>
                        <p>Already have an account?<a href="#">Login</a></p>
                    </div>
                </form>
            </div>
            <div className="col-md-6 text-end">
                <img src="assets/img/login-img.png" className="img-fluid w-100 h-100 object-fit-cover"/>
            </div>
        </div>
    </section>
        
    </div>
    );
}

export default Register;